@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Roboto:wght@400;700&display=swap');

.achievements-list a {
    color: #004488;
    text-decoration: none;
    background: none;
    font-weight: 700;
}

.achievements-list a:hover, .achievements-list a:active {
    color: #004488;
    text-decoration: none;
    font-size: 120%;
}

.achievements-list li span {
    flex: 2;
    text-align: left;
    color: #333333;
}

.achievements-list li strong {
    flex: 1;
    color: #004488;
    margin-right: 10px;
}

.achievements-list li {
    background-color: #f7f7f7;
    border-radius: 10px;
    margin: 10px 0;
    padding: 15px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.App {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
}

.article-card {
    background-color: #f7f7f7;
    border-radius: 10px;
    overflow: hidden;
    width: 200px;
    text-decoration: none;
    color: #333333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.article-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.article-info h3 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #004488;
    font-family: 'Merriweather', serif; /* Using a serif font for titles */
}

.article-info p {
    font-size: 12px;
    color: #666666;
}

.article-info {
    padding: 10px;
}

.article-thumbnail {
    width: 100%;
    height: 100px;
    object-fit: cover;
}

.articles h2 {
    color: #004488;
    margin-bottom: 20px;
    font-family: 'Merriweather', serif; /* Using a serif font for headings */
}

.articles p {
    color: #333333;
    margin-bottom: 20px;
}

.articles {
    padding: 20px;
}

.articles-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
}

.hamburger-icon {
    display: none;
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 24px;
    z-index: 1000;
    cursor: pointer;
}

.handwritten {
    font-family: 'Merriweather', serif;
    font-size: 36px;
    color: #333333;
    text-align: center;
    cursor: pointer;
    margin: 0;
}

.intro-block {
    height: 100px;
    transition: height 0.3s ease-in-out;
}

.intro-block, .content-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
}

.intro-block:hover .handwritten {
    color: #004488;
}

.main-content {
    padding: 10px;
    overflow-y: auto;
    height: 100vh;
}

.mobile-nav .social-icons a {
    color: white;
    font-size: 28px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.mobile-nav .social-icons a:hover {
    color: #0077b5;
}
.mobile-nav .social-icons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    padding: 10px 0;
}

.mobile-nav {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
}

.mobile-nav-links a {
    color: #fff;
    text-decoration: none;
    font-size: 24px;
}

.mobile-nav-links li {
    margin: 20px 0;
}

.mobile-nav-links {
    list-style: none;
    padding: 0;
}

.name-h2 {
    font-size: 24px;
    color: #004488;
    text-align: center;
}

.nav-links li a {
    color: #333333;
    font-size: 18px;
    font-weight: 400;
    margin-left: 10px;
    display: block;
    text-decoration: none;
}

.nav-links li a.active,
.nav-links li a:hover {
    color: #004488;
    font-weight: 700;
}

.nav-links li {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.profile-pic {
    filter: brightness(100%);
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.profile-pic-container {
    width: 100%;
    max-width: 200px;
    margin: 5px auto;
}

.section {
    padding: 40px;
    max-width: 800px;
    margin: auto;
}

.section.about .content-block {
    display: block;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.section.about p {
    margin-bottom: 15px;
}

.section.about {
    padding: 0;
    max-width: 800px;
    margin: 20px auto;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333333;
    font-size: 18px;
    line-height: 1.6;
}

.section.about.expanded .intro-block {
    height: 100%;
}

.sidebar {
    position: relative;
    width: 250px;
    height: 100vh;
    background-color: #f7f7f7;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.social-icons a {
    margin: 0 10px;
    color: #004488;
    font-size: 40px;
}

.social-icons a:hover {
    color: #C0B283;
}

.social-icons {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    text-align: center;
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    color: #333333;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

h2 {
    font-size: 28px;
    color: #004488;
}


@media (max-width: 768px) {
    .about.expanded .content-block p {
        word-wrap: break-word;
    }

    .about.expanded .content-block {
        padding: 15px;
        font-size: 16px;
        line-height: 1.5;
    }

    .achievements {
        width: 100%;
        padding: 0;
    }

    .achievements-list li span {
        display: flex;
        font-size: 16px;
        color: #333333;
        width: 100%;
        justify-content: center;
    }
    .achievements-list li strong {
        display: flex;
        margin-bottom: 10px;
        font-size: 18px;
        color: #004488;
        width: 100%;
        justify-content: center;
    }

    .achievements-list li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 10px;
        background-color: #f9f9f9;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
    }

    .achievements-list {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .article-card {
        width: 70%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
    }

    .article-info {
        padding: 5px;
        text-align: center;
    }

    .article-thumbnail {
        width: 100%;
        height: auto;
    }

    .articles-grid {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .hamburger-icon {
        display: block;
    }

    .mobile-nav {
        display: flex;
    }

    .section {
        width: 100%;
        padding: 0;
        box-sizing: border-box;
    }

    .sidebar {
        display: none;
    }
}
